import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
// Icons
import { BiTrophy } from 'react-icons/bi';
import { FaRegLightbulb } from 'react-icons/fa';
import { VscGraph } from 'react-icons/vsc';
import Button from '@atoms/Button/Button';
import Divider from '@atoms/Divider/Divider';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import Card from '@components/Card/Card';
import CardGallery from '@components/CardGallery/CardGallery';
import FatNumber from '@components/FatNumber/FatNumber';
import Footer from '@components/Footer/Footer';
import Link from '@components/Link';
import Header from '@components/Header/Header';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Layout from '@components/layout';
import Seo from '@components/seo';
import SideSection from '@components/SideSection/SideSection';
// Blocks
import BusinessFeatures from '../../../landingPage/BusinessFeatures';
import SignupFormBusiness from '../../../landingPage/SignupFormBusiness';
import { withLocale } from '@hocs';

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        headerMobileImage: file(
            relativePath: { eq: "business/header-companies-mobile.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headerDesktopImage: file(
            relativePath: { eq: "business/header-companies-desktop.jpg" }
        ) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 455) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        coachImage: file(relativePath: { eq: "home/home-feature4.png" }) {
            childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

const BusinessPage = ({ data: static_images }) => {
    const { t } = useTranslation();

    return (
        <Layout>
            <Seo
                title={t('for.business.seo.title')}
                description={t('for.business.seo.description')}
            />
            <div className="global_theme-green">
                <HeaderNav />
                <Section theme="light">
                    <Header
                        theme="light"
                        title={t('for.business.header.title')}
                        list={[
                            t('for.business.header.key.feature.one'),
                            t('for.business.header.key.feature.two'),
                            t('for.business.header.key.feature.three'),
                            t('for.business.header.key.feature.four'),
                            t('for.business.header.key.feature.five'),
                        ]}
                        toCredit={true}
                        btnPrimary={
                            <AnchorLink offset={() => 80} href="#anmelden">
                                <Button
                                    variant="primary"
                                    size="l"
                                    label={t('for.business.primary.cta.label')}
                                    isMobileFullsize={true}
                                />
                            </AnchorLink>
                        }
                        btnSecondary={
                            <a
                                href="https://app.aepsy.com/start"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button
                                    variant="naked"
                                    label={t(
                                        'for.business.access.to.employees.cta.label'
                                    )}
                                />
                            </a>
                        }
                        image={
                            <Img
                                className="Header--visual--img is-big"
                                fluid={
                                    static_images.headerDesktopImage
                                        .childImageSharp.fluid
                                }
                                alt="Header Image"
                            />
                        }
                        imageMobile={
                            <Img
                                className="Header--visual--img is-small"
                                fluid={
                                    static_images.headerMobileImage
                                        .childImageSharp.fluid
                                }
                                alt="Header Image"
                            />
                        }
                    />
                </Section>

                {/* Header Quote */}

                <div id="mehr">
                    <Section
                        theme="dark"
                        container="large"
                        spacingBottom="xl"
                        spacingTop="m"
                    >
                        <Title tag="p" size="xl" theme="white">
                            <Trans
                                i18nKey={'for.business.intro.statement.text'}
                                components={{ br: <br /> }}
                            />
                        </Title>
                    </Section>
                </div>

                {/* 3 Cards */}

                <Section theme="light" container="large">
                    <CardGallery size="3" layout="cascade">
                        <Card
                            format="v"
                            visual={
                                <FatNumber
                                    hero="1:4"
                                    tagline={t(
                                        'for.business.key.usp.one.tagline'
                                    )}
                                />
                            }
                            title={t('for.business.key.usp.one.title')}
                            text={t('for.business.key.usp.one.text')}
                        />

                        <Card
                            format="v"
                            visual={
                                <FatNumber
                                    hero="59+"
                                    tagline={t(
                                        'for.business.key.usp.two.tagline'
                                    )}
                                />
                            }
                            title={t('for.business.key.usp.two.title')}
                            text={t('for.business.key.usp.two.text')}
                        />

                        <Card
                            format="v"
                            visual={
                                <FatNumber
                                    hero="10"
                                    tagline={t(
                                        'for.business.key.usp.three.tagline'
                                    )}
                                />
                            }
                            title={t('for.business.key.usp.three.title')}
                            text={t('for.business.key.usp.three.text')}
                        />
                    </CardGallery>
                </Section>

                {/* Introducing Solution */}
                <Section container="short" spacing="m" theme="light">
                    <Title theme="light" align="center">
                        {t('for.business.highlighted.features.section.title')}
                    </Title>

                    <div className="g_1_4 g_center">
                        <Divider spacing="m" />
                    </div>

                    <div className="g_1_1 g_center">
                        <Title tag="h2" theme="dark" align="center">
                            {t(
                                'for.business.highlighted.features.section.subtitle'
                            )}
                        </Title>
                    </div>
                </Section>

                {/* Features */}
                <Section theme="light" container="large">
                    <BusinessFeatures />

                    <Divider spacing="l" invisible />

                    <AnchorLink offset={() => 80} href="#anmelden">
                        <Button
                            align="center"
                            size="l"
                            theme="highlighted"
                            label={t('for.business.primary.cta.label')}
                            iconArrow
                        />
                    </AnchorLink>
                </Section>

                {/* Wieso es sich lohnt? */}
                <Section
                    container="short"
                    spacingTop="m"
                    spacingBottom="l"
                    theme="light"
                >
                    <div className="g_1_1 g_center">
                        <Title tag="h2" theme="dark" align="center">
                            {t('for.business.key.value.section.title')}
                        </Title>
                    </div>
                    <div className="g_1_4 g_center">
                        <Divider spacing="m" />
                    </div>

                    <div className="g_1_2 g_center">
                        <Text align="center">
                            {t('for.business.key.value.section.subtitle')}
                        </Text>
                    </div>
                </Section>

                {/* 3 Cards */}
                <Section theme="dark" container="large" spacingBottom="s">
                    <CardGallery size="3" layout="cascade">
                        <Card
                            format="v"
                            visual={<FatNumber icon={<FaRegLightbulb />} />}
                            title={t('for.business.key.value.one.title')}
                            text={t('for.business.key.value.one.text')}
                        />

                        <Card
                            format="v"
                            visual={<FatNumber icon={<BiTrophy />} />}
                            title={t('for.business.key.value.two.title')}
                            text={t('for.business.key.value.two.text')}
                        />
                        <Card
                            format="v"
                            visual={<FatNumber icon={<VscGraph />} />}
                            title={t('for.business.key.value.three.title')}
                            text={t('for.business.key.value.three.text')}
                        />
                    </CardGallery>
                </Section>

                {/* The Process */}
                <div id="weg">
                    <Section container="short" spacingTop="m" theme="light">
                        <Title theme="dark" align="center">
                            {t('for.business.way.section.title')}
                        </Title>

                        <div className="g_1_4 g_center">
                            <Divider spacing="m" />
                        </div>

                        <div className="g_2_3 g_center">
                            <Text align="center">
                                {t('for.business.way.section.subtitle')}
                            </Text>
                        </div>
                    </Section>
                    <Section theme="light" container="short" spacingTop="l">
                        <CardGallery size="3" layout="cascade">
                            <Card
                                format="v"
                                visual={<FatNumber hero="1" />}
                                title={t('for.business.way.step.one.title')}
                                text={t('for.business.way.step.one.text')}
                            />
                            <Card
                                format="v"
                                visual={<FatNumber hero="2" />}
                                title={t('for.business.way.step.two.title')}
                                text={t('for.business.way.step.two.text')}
                            />
                            <Card
                                format="v"
                                visual={<FatNumber hero="3" />}
                                title={t('for.business.way.step.three.title')}
                                text={t('for.business.way.step.three.text')}
                            />
                        </CardGallery>
                    </Section>
                </div>

                {/* Quote */}
                <Section container="short" spacing="xl" theme="dark">
                    <div className="g_center">
                        <Title align="center" size="xl" theme="white">
                            “{t('for.business.quote.claim')}”
                        </Title>
                        <Text size="m" theme="white" align="center">
                            {t('for.business.quote.author')}
                        </Text>
                    </div>
                </Section>

                {/* Coaches */}
                <Section theme="white" spacing="m" container="large">
                    <SideSection
                        title={t('for.business.quality.provider.title')}
                        text={t('for.business.quality.provider.text')}
                        visual={
                            <Img
                                fluid={
                                    static_images.coachImage.childImageSharp
                                        .fluid
                                }
                                alt="Experten Image"
                            />
                        }
                        link={
                            <Link to="/approach/">
                                <Button
                                    variant="inline"
                                    theme="highlighted"
                                    label={t(
                                        'for.business.quality.provider.cta.label'
                                    )}
                                    iconArrow
                                />
                            </Link>
                        }
                    />
                </Section>

                <Section theme="white" container="short" spacingBottom="l">
                    <Title tag="h3" size="l" align="center" theme="dark">
                        {t('for.business.mini.contact.section.title')}
                    </Title>
                    <Divider invisible spacing="s" />
                    <Title tag="p" size="m" align="center">
                        {t('for.business.mini.contact.section.subtitle')}
                    </Title>
                    <div className="g_center">
                        <a href="mailto:hey@aepsy.com">
                            <Button
                                label="hey@aepsy.com"
                                theme="highlighted"
                                size="l"
                            />
                        </a>
                    </div>
                </Section>

                {/* SignupForm */}
                <div id="anmelden">
                    <Section theme="highlighted" spacing="m" container="large">
                        <SignupFormBusiness />
                    </Section>
                </div>
                <Footer />
            </div>
        </Layout>
    );
};

export default withLocale(BusinessPage);
