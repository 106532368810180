import React from 'react';
import { useTranslation } from 'react-i18next';
import SignupForm from '@components/SignupForm/SignupForm';

export default function SignupFormBusiness() {
    const { t } = useTranslation();
    return (
        <SignupForm
            title={t('for.business.signup.section.title')}
            description={t('for.business.signup.section.description')}
            cta={t('for.business.signup.section.cta.label')}
            list={[
                t('for.business.signup.section.step.one'),
                t('for.business.signup.section.step.two'),
                t('for.business.signup.section.step.three'),
            ]}
            from="business"
        />
    );
}
