import { graphql, useStaticQuery } from 'gatsby';
import Link from '@components/Link';

import Img from 'gatsby-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@atoms/Button/Button';
import SideSection from '@components/SideSection/SideSection';

export default function BusinessFeatures() {
    const { t } = useTranslation();

    const image_data = useStaticQuery(graphql`
        query {
            feature_01: file(
                relativePath: { eq: "business/business-feature-01.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            feature_02: file(
                relativePath: { eq: "business/business-feature-02.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            feature_03: file(
                relativePath: { eq: "business/business-feature-03.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            feature_04: file(
                relativePath: { eq: "business/business-feature-04.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <>
            <SideSection
                title={t('for.business.highlighted.features.matching.title')}
                text={t('for.business.highlighted.features.matching.text')}
                visual={
                    <Img
                        fluid={image_data.feature_01.childImageSharp.fluid}
                        alt={
                            t(
                                'for.business.highlighted.features.matching.title'
                            ) + ' - Visual'
                        }
                    />
                }
                link={
                    <Link to="/providers">
                        <Button
                            variant="inline"
                            theme="highlighted"
                            label={t(
                                'for.business.highlighted.features.matching.cta.label'
                            )}
                            iconArrow
                        />
                    </Link>
                }
            />
            <SideSection
                title={t('for.business.highlighted.features.medium.title')}
                text={t('for.business.highlighted.features.medium.text')}
                visual={
                    <Img
                        fluid={image_data.feature_02.childImageSharp.fluid}
                        alt={
                            t(
                                'for.business.highlighted.features.medium.title'
                            ) + ' - Visual'
                        }
                    />
                }
            />
            <SideSection
                title={t('for.business.highlighted.features.selfcare.title')}
                text={t('for.business.highlighted.features.selfcare.text')}
                visual={
                    <Img
                        fluid={image_data.feature_03.childImageSharp.fluid}
                        alt={
                            t(
                                'for.business.highlighted.features.selfcare.title'
                            ) + ' - Visual'
                        }
                    />
                }
            />

            <SideSection
                title={t('for.business.highlighted.features.easy.setup.title')}
                text={t('for.business.highlighted.features.easy.setup.text')}
                visual={
                    <Img
                        fluid={image_data.feature_04.childImageSharp.fluid}
                        alt={
                            t(
                                'for.business.highlighted.features.easy.setup.title'
                            ) + ' - Visual'
                        }
                    />
                }
            />
        </>
    );
}
