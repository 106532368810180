import firebase from 'gatsby-plugin-firebase';
import React, { useState } from 'react';
import Text from '@atoms/Text/Text';
import './TruncateText.scss';

TruncateText.defaultProps = {
    char: 60,
    moreLabel: 'Mehr anzeigen',
    lessLabel: 'Weniger anzeigen',
};

export default function TruncateText(props) {
    const [aboutOpen, setAboutOpen] = useState(false);

    function truncate(str) {
        return str.length > props.char
            ? str.substring(0, props.char - 3) + '...'
            : str;
    }

    function handleToggle() {
        if (!aboutOpen) {
            firebase.analytics().logEvent('more_text_toggle', {
                text_content: props.children,
            });
        }

        setAboutOpen(!aboutOpen);
    }

    return (
        <div className="TruncateText" onClick={() => handleToggle()}>
            <Text tag="div" align="left" size="s">
                {aboutOpen ? props.children : truncate(props.children)}{' '}
                <span className="is-link" onClick={() => handleToggle()}>
                    {aboutOpen ? props.lessLabel : props.moreLabel}
                </span>
            </Text>
        </div>
    );
}
