import React from 'react';
import './_FatNumber.scss';

FatNumber.defaultProps = {
    theme: 'highlight', // light, highlight, dark
};

function FatNumber(props) {
    let mod_class = [
        'FatNumber',
        props.theme !== undefined ? 'theme-' + props.theme : '',
    ];
    mod_class = mod_class.join(' ');

    function renderEl() {
        if (props.icon !== undefined) {
            return <strong className="FatNumber--hero">{props.icon}</strong>;
        } else if (props.emoji !== undefined) {
            return (
                <img
                    className="FatNumber--hero FatNumber--emoji"
                    src={props.emoji}
                    alt="emoji"
                />
            );
        } else {
            if (props.taglineTop) {
                return (
                    <>
                        <span className="FatNumber--tagline">
                            {props.tagline}
                        </span>
                        <strong className="FatNumber--hero">
                            {props.hero}
                        </strong>
                    </>
                );
            } else {
                return (
                    <>
                        <strong className="FatNumber--hero">
                            {props.hero}
                        </strong>
                        <span className="FatNumber--tagline">
                            {props.tagline}
                        </span>
                    </>
                );
            }
        }
    }

    return <div className={mod_class}>{renderEl()}</div>;
}

export default FatNumber;
