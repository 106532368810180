import React from 'react';

import './_CardGallery.scss';

CardGallery.defaultProps = {
    size: '2', // 3
};

function CardGallery(props) {
    let mod_class = [
        'CardGallery',
        props.size !== undefined ? 'size-' + props.size : '',
        props.layout !== undefined ? 'layout-' + props.layout : '',
        props.variant !== undefined ? 'variant-' + props.variant : '',
    ];
    mod_class = mod_class.join(' ');

    return <div className={mod_class}>{props.children}</div>;
}

export default CardGallery;
